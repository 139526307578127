function Team({ name, post, img }) {
    return (
        <div className="flex flex-col items-center my-6 lg:mx-6 py-9 max-w-[280px] min-w-[240px] w-full lg:w-260px space-y-5 border border-white rounded-3xl">
            <img className="lg:w-[12vw]" src={img} alt="" />
            <h2 className="riffic text-[28px]">{name}</h2>
            <div className="text-[18px]">{post}</div>
            <div className="flex justify-evenly w-full">
                <img src="/assests/insta.png" alt="" />
                <img src="/assests/disc.png" alt="" />
                <img src="/assests/tweet.png" alt="" />
            </div>
        </div>
    )
}

export default Team

import { MenuAlt1Icon, XIcon } from '@heroicons/react/outline'
import { useEffect, useState } from 'react'

function Header() {
    const [open, setOpen] = useState(false)
    const [show, handleShow] = useState(false);

    useEffect(() => {
      window.addEventListener("scroll", () => {
        if (window.scrollY > 120) {
          handleShow(true);
        } else handleShow(false);
      });
      return () => {
        window.removeEventListener("scroll");
      };
    }, []);

    return (
        <div className={`px-10 md:px-20 flex items-center sticky top-0 z-50 bg-header-bg py-6 ${show && 'py-4'}`}>
            <a href="/"><img className={`max-w-[162px] max-h-[54px] sm:max-w-[212px] sm:max-h-[74px] ${show && 'sm:max-w-[170px] max-w-[142px]' } w-full h-full `} src="/assests/Logo.png" alt="" /></a>
            <div className={`hidden text-white lg:relative lg:flex lg:ml-auto items-center justify-center space-x-[38px]`}>
                <ul className="flex lg:space-x-[38px] text-[18px] font-medium ">
                    <li className='cursor-pointer'><a href="#about">About</a></li>
                    <li className='cursor-pointer'><a href="#roadmap">Roadmap</a></li>
                    <li className='cursor-pointer'><a href="#team">Team</a></li>
                    <li className='cursor-pointer'><a href="#faq">FAQ</a></li>
                </ul>
                <button className="px-[16px] py-[12px] w-[199px] h-[52px] mintButton">MINT NOW</button>
            </div>
            <MenuAlt1Icon onClick={() => setOpen(!open)} className='lg:hidden w-10 sm:w-14 text-white ml-auto' />
            <div className={`headerAnimate lg:hidden bg-royal-black h-screen fixed flex flex-col items-end px-10 py-9 top-0  right-0 bottom-0 text-white z-50 ${open ? 'translate-x-0' : 'translate-x-[110%]'} `}>
                <XIcon className='w-14' onClick={() => setOpen(!open)} />
                <ul className='py-6 px-10 text-3xl font-medium'>
                    <li className='py-4' onClick={() => setOpen(!open)} ><a href="#about">About</a></li>
                    <li className='py-4' onClick={() => setOpen(!open)} ><a href="#roadmap">Roadmap</a></li>
                    <li className='py-4' onClick={() => setOpen(!open)} ><a href="#team">Team</a></li>
                    <li className='py-4' onClick={() => setOpen(!open)} ><a href="#faq">FAQ</a></li>
                </ul>
            </div>
        </div>
    )
}

export default Header

const DATA = [
    {
        name: 'Plush Bags',
        content: 'Plush bags are our OG passes to the Plush Fight League. They have 10 different color variations (but no rarity). They are the golden tickets to our world, and an access key to your first Plushie NFT. They were minted and retrieved by our early supporters and most active members of the community. <br />Holders of a Plush Bag receive: <br />- An airdrop of a Plushie, during mint. <br />- Priority access to new features and game updates <br />- An airdrop of the upcoming token <br />- A shared % of Plushie royalties <br />  <br />As you can see, they’re very valuable, as our first members are. For now, they’re not listed in any marketplace per decision of the Plushy DAO. <br />'
    },
    {
        name: 'Plushies',
        content: 'Plushies are the core NFT of the Premier Fight League. They are playable, evolving characters that you’ll be able to use to play. Only a total of 10.000 Gen-1 Plushies will ever exist. They are 6 different animals customized with randomly selected attributes and accessories to make them unique. <br />They are both adorable and fierce. Just look at them fighting. <br />  <br />When mint: TBA <br />Mint size: TBA <br />Mint price: 1 SOL <br />  <br />'
    },
    {
        name: 'The Game',
        content: 'YES! It’s a brawl game. Look at it. Isn’t it amazing? We’ve partnered with an experienced team that has already developed successful games to bring the best experience to our players. It’ll be a Play-to-earn game (P2E) with multiple benefits for holders like staking and renting, besides having a lot of fun playing. <Br />When can I play? You’ll be able to play an offline demo in 2 weeks (before 2022). <Br />  <Br />When can I play? The game should be ready before the end of Q1 2022. Yes! That soon! <Br />  <Br />'
    }
]

function Section6() {
    return (
        <section id="about" className="section6 px-12 py-36 md:px-24 bg-header-bg space-y-16">
            <div className='flex flex-col items-center lg:flex-row lg:space-x-0 space-y-6 lg:space-y-0'>
                <div className="lg:w-1/2"> 
                    <img className="max-w-[716px] w-full lg:w-[38vw] " src="/assests/PlushBagsRectNew.png" alt="" />
                </div>
                <div className="text-white lg:w-1/2 flex flex-col justify-center items-center">
                    <div className='space-y-6 xl:w-4/4'>
                        <h1 className="poppins text-[36px] sm:text-[48px] text-center md:text-left">{DATA[0].name}</h1>
                        <p className="text-[16px] sm:text-[20px] text-center md:text-left" dangerouslySetInnerHTML={{__html: DATA[0].content}}></p>
                    </div>
                </div>
            </div>
            <div className='flex flex-col items-center lg:flex-row-reverse lg:space-x-0 space-y-6 lg:space-y-0'>
                <div className="lg:w-1/2 flex justify-center"> 
                    <img className="max-w-[716px] w-full lg:w-[38vw] " src="/assests/PlushiesRectNew.png" alt="" />
                </div>
                <div className="text-white lg:w-1/2 flex flex-col justify-center ">
                    <div className='space-y-6 xl:w-4/4'>
                        <h1 className="poppins text-[36px] sm:text-[48px]  text-center md:text-left">{DATA[1].name}</h1>
                        <p className="text-[16px] sm:text-[20px] text-center md:text-left"  dangerouslySetInnerHTML={{__html: DATA[1].content}}></p>
                    </div>
                </div>
            </div>
            <div className='flex flex-col items-center lg:flex-row lg:space-x-0 space-y-6 lg:space-y-0'>
                <div className="lg:w-1/2"> 
                    <img className="max-w-[716px] w-full lg:w-[38vw] " src="/assests/TheGameNew.png" alt="" />
                </div>
                <div className="text-white lg:w-1/2 flex flex-col justify-center items-center">
                    <div className='space-y-6 xl:w-4/4'>
                        <h1 className="poppins text-[36px] sm:text-[48px]  text-center md:text-left">{DATA[2].name}</h1>
                        <p className="text-[16px] sm:text-[20px] text-center md:text-left"  dangerouslySetInnerHTML={{__html: DATA[2].content}}></p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Section6

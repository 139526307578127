import { useState } from "react"
import Accordion from "./Accordion"

function Section8() {
    const [showNFT, setShowNFT] = useState(false)
    return (
        <section id='faq' className="section8 px-10 py-28 xl:px-24 text-white ">
            <h1 className="poppins decorated-text flex justify-center text-[48px] font-bold">FAQ</h1>
            <div className="flex flex-col space-y-8 items-center sm:space-y-0 sm:flex-row justify-center py-10 sm:space-x-10">
                <button onClick={() => setShowNFT(false)} className={` ${!showNFT ? 'mintButton' : 'border-2 poppins'}  flex !text-[20px]  items-center justify-center space-x-4 sm:space-x-6 max-w-[270px] md:w-[270px] w-full h-[74px] !rounded-full`}>ABOUT THIS PROJECT</button>
                <button onClick={() => setShowNFT(true)} className={`${showNFT ? 'mintButton' : 'border-2 poppins'}   !text-[20px]  font-bold flex items-center justify-center max-w-[270px] md:w-[270px] w-full h-[74px] !rounded-full`}>ABOUT NFT</button>
            </div>
            <div className="py-6 space-y-8 flex flex-col items-center">
                {
                    !showNFT ? 
                    <>
                        <Accordion question={'What are the details for minting?'} answer={'10.000 Gen-1 Plushies will be minted in the public sale on December, 2021 (Date TBA). 2.000 will be airdropped to Plush Bag holders and the rest will be available for public mint. '}/>
                        <Accordion question={'What is the difference between Plush Bags and Plushies?'} answer={'Plush Bags are a limited collection of access keys for the early supporters, carrying multiple benefits, while the Plushies are the playable NFT characters you can play with.'}/>
                        <Accordion question={'How do Plush Bags earn royalties?'} answer={'The royalty share will depend on how many Plush Bags a wallet holds. <br />Holding: <br />1 Plush Bags = 0% royalty split <br />2 Plush Bags = 2 parts of the shared 10% royalty split  <br />3 Plush Bags = 3 parts of the shared 50% royalty split  <br />4 Plush Bags = 1 part of 0% royalty split + 3 parts of the shared 50% royalty split <br />5 Plush Bags = 2 parts of 10% royalty split + 3 parts of the shared 50% royalty split <br />6 Plush Bags = 6 parts of the shared 50% royalty split <br />etc... <br />'}/>
                        <Accordion question={'Is it a Play-to-Earn game?'} answer={'Yes! <br />About NFTs '}/>
                    </> 
                    :
                    <>
                        <Accordion question={'What is an NFT?'} answer={'Non-Fungible Tokens are unique, easily verifiable digital assets that can represent items such as GIFs, images, videos, music albums, and more. Anything that exists online can be purchased as an NFT, theoretically. NFTs are different from cryptocurrencies because they’re not interchangeable. Think of Pokémon cards: You can trade them, but a Gastly is not the same as a holographic Charizard. But a bitcoin is indistinguishable from another bitcoin. '}/>
                        <Accordion question={'Why would I want to own an NFT? Can I make money on it?'} answer={'One reason to buy an NFT is for its emotional value, which isn’t so different from physical objects… unless you’re a total utilitarian. No one buys lip gloss because they need it. They buy it for the way it makes them feel. The same can be true for a GIF, image, video, or other digital asset. <br />Another reason to buy it is because you think it’s valuable, and you think it’ll only increase in value over time. So, yes, you can make money off of an NFT by buying it and reselling it for more. But this is Not Financial Advice. '}/>
                        <Accordion question={'How do you know your NFT is authentic?'} answer={"NFT ownership is recorded on the blockchain, and that entry acts as a digital pink slip. Our NFTs will be encoded using Metaplex's smart contract on the Solana Blockchain."}/>
                        <Accordion question={'Which wallets will I be able to use to mint an NFT?'} answer={'We offer a number of wallet connections for minting, but Phantom is the recommended wallet.'}/>
                        <Accordion question={'How can you ensure the distribution of mints is random?'} answer={'We will be using a smart contract deployed on the Solana chain to distribute the Plush Fight League. The tokens can be verified on the blockchain and our smart contract code can be audited by the Solana core devs. We are aware of concerns existing in the current system and we’ll do everything in our power to guarantee absolute transparency and randomness.'}/>
                        <Accordion question={'If you have any other questions, we have a Discord channel just for you. We’ll be happy to answer all of them. '} />
                    </>
                }
            </div>
            {/* answer={'<input placeholder="Please Type here" class="accordion-input w-full bg-inherit focus:ring-0 focus:outline-none" type="text" />'} */}
        </section>
    )
}

export default Section8

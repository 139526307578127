import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ReactPlayer from 'react-player'
import movie1 from '../../assests/1.mp4'
import movie2 from '../../assests/2.mp4'
import movie3 from '../../assests/3.mp4'
import movie4 from '../../assests/4.mp4'
import movie5 from '../../assests/5.mp4'
import { useState } from "react";

function Section2() {
    const [active, setActive] = useState(0)

    const slideChange = (index) => console.log(index)
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        initialSlide: 0,
        afterChange: current => setActive(current)
      };
    return (
        <div className="px-12 section2 py-28 xl:px-24 text-white mx-auto ">
            <Slider className=" flex justify-center" {...settings}>
                <div className="!flex justify-center">
                    <ReactPlayer muted={true} key={0} playing={active == 0} url={movie1} controls={true} width={890} height={'100%'} style={{maxWidth: '890px', maxHeight: '516px', width: '100%', height: '100%'}} />
                </div>
                <div className="!flex justify-center">
                    <ReactPlayer muted={true} key={1} playing={active == 1} url={movie2} controls={true} width={890} height={'100%'} style={{maxWidth: '890px', maxHeight: '516px', width: '100%', height: '100%'}} />
                </div>
                <div className="!flex justify-center">
                    <ReactPlayer muted={true} key={2} playing={active == 2} url={movie3} controls={true} width={890} height={'100%'} style={{maxWidth: '890px', maxHeight: '516px', width: '100%', height: '100%'}} />
                </div>
                <div className="!flex justify-center">
                    <ReactPlayer muted={true} key={3} playing={active == 3} url={movie4} controls={true} width={890} height={'100%'} style={{maxWidth: '890px', maxHeight: '516px', width: '100%', height: '100%'}} />
                </div>
                <div className="!flex justify-center">
                    <ReactPlayer muted={true} key={4} playing={active == 4} url={movie5} controls={true} width={890} height={'100%'} style={{maxWidth: '890px', maxHeight: '516px', width: '100%', height: '100%'}} />
                </div>
            </Slider>
        </div>
    )
}

export default Section2

function Section4() {
    return (
        <div className='gradient-bg bg-header-bg px-12 py-[93px] flex flex-col justify-center items-center text-white'>
            {/* <img className="max-w-[230px] max-h-[234px] w-full h-full" src="/assests/Golden_star.png" alt="" /> */}
            <div className="poppins txt-sdw text-[36px] sm:text-[48px] font-normal my-4">The Ecosystem</div>
            <div className="text-[18px] my-2 max-w-[960px] w-full text-center font-semibold">Plush Fight League is an ecosystem of online PvP/P2E games where you can play using your Plushies and other NFT characters. Read more about our first game. Plushies are the cutest, fiercest fighters of the whole Solana ecosystem. Gen-1 dropping very, very soon.</div>
        </div>
    )
}

export default Section4
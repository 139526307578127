function CardWrap({ img, title, content }) {
    return (
        <div className="flex flex-col max-w-[354px] w-full justify-start py-6 items-center">
            <div className="relative">
                <img className="xl:max-h-max xl:max-w-max w-full h-full xl:w-[22vw] xl:h-[22vw]" src={img} alt="" >
                </img>
            </div>
            <div className="poppins text-[36px] sm:text-[48px] mt-8">{title}</div>
            { content && <div className="text-[16px] font-semibold text-center mt-4" dangerouslySetInnerHTML={{__html: content}}></div>}
        </div>
    )
}

export default CardWrap

import CardWrap from "./CardWrap"

function ExtraRoundSection() {
    return (
        <div id="extraSection" className="px-12  py-28 md:px-24 w-full flex flex-col lg:flex-row flex-wrap lg:space-x-12 h-full justify-center lg:justify-between items-center lg:items-start text-white" >
            <CardWrap img="/assests/PlushBagsRound.png" title={'PLUSH BAGS'} content={'Plush bags are our OG passes to the Plush Fight League. They have 10 different color variations (but no rarity). They are the golden tickets to our world, and an access key to your first Plushie NFT. They were minted and retrieved by our early supporters and most active members of the community. <br />Holders of a Plush Bag receive: <br />- An airdrop of a Plushie, during mint. <br />- Priority access to new features and game updates <br />- An airdrop of the upcoming token <br />- A shared % of Plushie royalties <br />  <br />As you can see, they’re very valuable, as our first members are. For now, they’re not listed in any marketplace per decision of the Plushy DAO. <br />'} />
            <CardWrap img="/assests/PlushiesRound.png" title={'PLUSHIES'} content={'Plushies are the core NFT of the Premier Fight League. They are playable, evolving characters that you’ll be able to use to play. Only a total of 10.000 Gen-1 Plushies will ever exist. They are 6 different animals customized with randomly selected attributes and accessories to make them unique. <br />They are both adorable and fierce. Just look at them fighting. <br />  <br />When mint: TBA <br />Mint size: TBA <br />Mint price: 1 SOL <br />  <br />'} />
            <CardWrap img="/assests/TheGameRound.png" title={'THE GAME'} content={'YES! It’s a brawl game. Look at it. Isn’t it amazing? We’ve partnered with an experienced team that has already developed successful games to bring the best experience to our players. It’ll be a Play-to-earn game (P2E) with multiple benefits for holders like staking and renting, besides having a lot of fun playing. <Br />When can I play? You’ll be able to play an offline demo in 2 weeks (before 2022). <Br />  <Br />When can I play? The game should be ready before the end of Q1 2022. Yes! That soon! <Br />  <Br />'} />
        </div>
    )
}

export default ExtraRoundSection

import { useState } from 'react'
import VisibilitySensor from 'react-visibility-sensor'

function RoadMap({ left, title, content }) {
    const [visible, setVisible] = useState(false)
    const onVisible = (isVisible) => {
        setVisible(isVisible)
    }
    return (
    <VisibilitySensor onChange={onVisible} partialVisibility={true} minTopValue={250}>
        <div className="flex relative pb-10">
            {/* Left */}
            <div className={`${left ? 'flex': 'hidden'} w-full md:flex flex-col justify-center items-center md:w-1/2`}>
                {left &&
                    <div className="w-full lg:w-3/4 space-y-4 sm:space-y-8 md:mr-32">
                        <h1 className="poppins decorated-text text-[32px] md:text-[45px] font-medium text-center">{title}</h1>
                        <p className="text-[20px] sm:text-[24px] md:text-[28px] text-center text-gray-400" dangerouslySetInnerHTML={{ __html: content}}>
                        </p>
                    </div>
                }
            </div>
            {/* Right */}
            <div className={`${!left ? 'flex': 'hidden'} w-full md:flex flex-col justify-center items-center md:w-1/2`}>
                {!left && 
                    <div className="lg:w-3/4 space-y-4 sm:space-y-8 md:ml-32">
                        <h1 className="poppins decorated-text text-[32px] md:text-[45px] font-medium text-center">{title}</h1>
                        <p className="text-[20px] sm:text-[24px] md:text-[28px] text-center text-gray-400" dangerouslySetInnerHTML={{ __html: content}}>
                        </p>
                    </div>
                }
            </div>
            <div></div>
            <div className="hidden absolute top-0 left-0 right-0 bottom-0 md:flex flex-col items-center">
                <div className={` relative w-[50px] h-[50px] ${visible ? 'bg-white' : 'bg-roadmap-before' } rounded-full`}>
                <div className={` relative w-[50px] h-[50px] ${visible ? 'bg-white' : 'bg-roadmap-before' } rounded-full`} />
                    {/* Left && Right */} 
                    { left ? 
                        <div className="absolute top-0 justify-center bottom-0 right-0 flex flex-col items-center">
                            <div className={`w-32 lg:w-36 h-1 ${visible ? 'bg-white' : 'bg-roadmap-before' }`}></div>
                        </div>
                        :
                        <div className="absolute top-0 justify-center bottom-0 left-0 flex flex-col items-center">
                            <div className={`w-32 lg:w-36 h-1 ${visible ? 'bg-white' : 'bg-roadmap-before' }`}></div>
                        </div>

                    }
                </div>
                {/* <div className='h-full w-3 ${visible ? 'bg-white' : 'bg-roadmap-before' }' /> */}
            </div>
            <div className="-ml-8 -mt-1 absolute top-0 left-0 right-0 bottom-0 md:hidden flex-col items-center">
                {/* <div className={` absolute w-[26px] h-[26px] ${visible ? 'bg-white' : 'bg-roadmap-before' } z-10 rounded-full`}> */}
                {/* </div> */}
            </div>
            <div className="-ml-6 absolute top-0 left-0 right-0 bottom-0 md:ml-0 md:flex flex-col items-center">
                <div className={`-ml-[11px] -mt-1 absolute w-[26px] h-[26px] md:hidden ${visible ? 'bg-white' : 'bg-roadmap-before' } z-10 rounded-full`} />
                <div className={`h-full w-1 ${visible ? 'bg-white' : 'bg-roadmap-before' }`} />
            </div>
        </div>
    </VisibilitySensor>
    )
}

export default RoadMap

function Section10() {
    return (
        <div className="px-12 py-28 xl:px-24 text-white space-y-6 bg-header-bg">
            <h1 className="poppins decorated-text text-[40px] sm:text-[48px] font-medium flex justify-center text-center">Meet Our Partners</h1>
            <div className="flex flex-wrap justify-evenly items-center">
                <img className="py-2" src="/assests/1.png" alt="" />
                <img className="py-2" src="/assests/2.png" alt="" />
                <img className="py-2" src="/assests/3.png" alt="" />
                <img className="py-2" src="/assests/4.png" alt="" />
                <img className="py-2" src="/assests/5.png" alt="" />
                <img className="py-2" src="/assests/6.png" alt="" />
            </div>
        </div>
    )
}

export default Section10

import RoadMap from "./RoadMap"

function Section7() {
    return (
        <section id="roadmap" className="px-12 py-28 xl:px-24 text-white space-y-10">
            <h1 className="poppins decorated-text font-bold flex flex-col items-center text-[48px] md:text-[56px] pb-10">Road Map</h1>
            <div>
                <RoadMap left={false} title={'September'} content={'Reveal project <br />Increase social media presence <br />Start building plushie community <br />Expand team'} />
                <RoadMap left={true} title={'October'} content={'Launch website <br />Continue building plushie community <br />Plush Bag sale <br />Finalize character designs <br />Launch marketing campaign'} />
                <RoadMap left={false} title={'November'} content={'Plush Bags SOLD OUT <br />Start Plush Bag benefit distribution <br />Set up Plush DAO'} />
                <RoadMap left={true} title={'December'} content={'Game alpha version reveal <br />Playable beta version <br />Plushies NFT sale <br />Christmas charity efforts'} />
                <RoadMap left={false} title={'2022 and Beyond'} content={'WebGL game access release <br />Plush Bag and Plushie holders benefit distribution <br />Play to Earn launch <br />Public token sale Mobile game release '} />
            </div>
        </section>
    )
}

export default Section7

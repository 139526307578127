import ExtraRoundSection from "../components/Home/ExtraRoundSection"
import Section1 from "../components/Home/Section1"
import Section10 from "../components/Home/Section10"
import Section11 from "../components/Home/Section11"
import Section2 from "../components/Home/Section2"
import Section3 from "../components/Home/Section3"
import Section4 from "../components/Home/Section4"
import Section5 from "../components/Home/Section5"
import Section6 from "../components/Home/Section6"
import Section7 from "../components/Home/Section7"
import Section8 from "../components/Home/Section8"
import Section9 from "../components/Home/Section9"

function Home() {
    return (
        <div>
            <Section1 />
            <Section2 />
            <ExtraRoundSection />
            {/* <Section3 /> */}
            <Section4 />
            <ExtraRoundSection />
            {/* <Section5 /> */}
            <Section6 />
            <Section7 />
            <Section10 />
            <Section9 />
            <Section8 />
            <Section11 />
        </div>
    )
}

export default Home

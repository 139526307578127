function Section11() {
    return (
        <div className="px-12 py-12 xl:px-24 text-white flex flex-col items-center bg-black">
            <a href="/"><img className="min-w-[200px] w-[264px]" src="/assests/Logo.png" alt="" /></a>
            <ul className="flex space-x-4 sm:space-y-0 sm:flex-row items-center flex-wrap sm:space-x-8 py-6 max-w-lg w-full justify-evenly">
                <a href="/"><li>Home</li></a>
                <li>Features</li>
                <li>Collection</li>
                <a href="#roadmap"><li>Roadmap</li></a>
                <a href="#team"><li>The Creator</li></a>
            </ul>
            <div className="flex flex-wrap space-x-5 sm:space-x-10 py-2 justify-center">
                <img src="/assests/insta_gray.png" alt="" />
                <img src="/assests/disc_gray.png" alt="" />
                <img src="/assests/tweet_gray.png" alt="" />
                <img src="/assests/tele.png" alt="" />
                <img src="/assests/ytb.png" alt="" />
            </div>
            <p className="flex text-[14px] text-gray-400 py-4 text-center">Copyright © 2021, All rights reserved.</p>
            <div className="flex flex-wrap text-[14px] text-center space-x-5 justify-evenly">
                <div className="py-1">Terms of Services</div>
                <div className="py-1">Privacy Policy</div>
                <div className="py-1">Disclaimers</div>
            </div>
        </div>
    )
}

export default Section11

import { PlusIcon, MinusIcon } from '@heroicons/react/outline'
import { useState } from 'react'

function Accordion({ question, answer }) {
    const [open, setOpen] = useState(false)
    return (
        <div className="max-w-[80vw] w-full py-4 px-4 sm:py-6 border sm:px-8 border-white rounded-xl">
            <div onClick={() => setOpen(!open)} className='riffic cursor-pointer font-medium flex justify-between text-[20px] md:text-[28px]'>{question} { !open ? <PlusIcon className='w-6' /> : <MinusIcon className='w-6' />}</div>
            <div className={` text-[20px] md:text-[28px]  ${open ? 'max-h-max pt-4' : 'max-h-0'} overflow-hidden transition-all duration-300 ease-in-out`} dangerouslySetInnerHTML={{__html: answer}}></div>
        </div>
    )
}

export default Accordion

import Team from "./Team"

function Section9() {
    return (
        <section id='team' className="px-12 py-28 xl:px-24 bg-royal-black text-white space-y-8">
            <h1 className="poppins decorated-text text-[36px] sm:text-[48px] flex justify-center">Plush Family</h1>
            <div className="flex w-fit flex-col mx-auto lg:flex-row lg:flex-wrap justify-center">
                <Team name={'Rasto'} post={'(Game Developer)'} img={'/assests/Rasto.png'} />
                <Team name={'Snowie'} post={'Artist'} img={'/assests/Snowie.png'} />
                <Team name={'Frank'} post={'Community Manager'} img={'/assests/Frank.png'} />
                <Team name={'Snowie'} post={'Artist'} img={'/assests/Snowie.png'} />
                <Team name={'Snowie'} post={'Artist'} img={'/assests/Snowie.png'} />
                <Team name={'Rasto'} post={'(Game Developer)'} img={'/assests/Rasto.png'} />
                <Team name={'Snowie'} post={'Artist'} img={'/assests/Snowie.png'} />
                <Team name={'Frank'} post={'Community Manager'} img={'/assests/Frank.png'} />
            </div>
        </section>
    )
}

export default Section9

function Section1() {
    return (
        <section id='' className="px-12 md:px-24 flex section1 items-center flex-col xl:flex-row py-20 sm:py-28 xl:justify-evenly ">
            <div className="text-white xl:w-6/12 flex flex-col md:items-center xl:items-start">
                <h1 className="poppins decorated-text text-5xl sm:text-6xl py-4 md:text-8xl font-bold text-center md:text-center xl:text-left">Plush Fight League</h1>
                <h1 className="font-medium py-[26px] text-[24px] sm:text-[28px] text-center md:text-center xl:text-left">The Premier Fight League of the Metaverse</h1>
                <div className="flex space-y-6 mb-10 md:space-y-0 md:space-x-6  flex-col md:flex-row  items-center">
                    <button className="mintButton  flex items-center justify-center space-x-4 sm:space-x-6 max-w-[256px] md:w-[256px] w-full h-[74px] rounded-[10px]"><img src="/assests/discord.svg" alt="" /><span>JOIN DISCORD</span></button>
                    <button className="poppins text-[20px]  font-bold flex items-center justify-center space-x-4 sm:space-x-6 max-w-[256px] md:w-[256px] w-full h-[74px] rounded-[10px] border-2"><img src="/assests/twitter.png" alt="" /><span>TWITTER</span></button>
                </div>
            </div>
            <div className="Box  max-w-[466px] max-h-[466px] xl:max-h-max xl:max-w-max w-full h-full xl:w-[30vw] xl:h-[30vw] flex justify-center">
                <img src="/assests/MainLogo.png" alt="" />
            </div>
        </section>
    )
}

export default Section1
